import "./styles.less";

import { Checkbox, Col, Row, Select, Skeleton, Space } from "antd";

import React, { useContext } from "react";
import { usePropertyType } from "./PropertyType.hook";
import { useTheme } from "../../../Styles/ThemeHook";
import { IC_Collapse } from "shared-components/Components/Collapse/web";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";

const { Option } = Select;

export const PropertyType = ({ collapsable = false, borderRadio=false, ...props }) => {
	const { theme } = useTheme();
	const { country_code } = useContext(ConfigStateContext);
	const {
		show,
		error,
		loading,
		inputType,
		label,
		labeled,
		data: { options, onChange, value = [], customKey },
	} = usePropertyType(props);

	if (!show) return null;
	if (error) return null;

	const handleChange = checked => {
		const res = options.filter(o => checked.includes(o[customKey]));
		onChange(res);
	};


	const checkboxFilter = loading ? (
		<Space style={{ width: "100%" }} size={0} direction={"vertical"}>
			<Skeleton title={false} active paragraph={{ rows: 1, width: "100%" }} />
			<Skeleton title={false} active paragraph={{ rows: 1, width: "100%" }} />
			<Skeleton title={false} active paragraph={{ rows: 1, width: "100%" }} />
		</Space>
	) : (
		<Checkbox.Group value={value} onChange={handleChange}>
			<Row gutter={[0, theme.spacing.lgSpacing]}>
				{options?.map(o => (
					<Col span={24} key={`key_${o[customKey]}_propertyType`}>
						<Checkbox value={o[customKey]}>{o.name}</Checkbox>
					</Col>
				))}
			</Row>
		</Checkbox.Group>
	);

	const selectFilter = loading ? (
		<Skeleton.Button active className="property-type-skeleton" />
	) : (
		<Select
			disabled={loading}
			value={loading ? "Tipo de Propiedad" : value}
			mode={"multiple"}
			showArrow
			optionFilterProp="children"
			onChange={handleChange}
			style={{ width: "100%" }}
			placeholder={"Seleccione un Tipo de Propiedad"}>
			{options?.map((o, index) => (
				<Option key={`name_${o[customKey]}_${index}`} value={o[customKey]}>
					{o.name}
				</Option>
			))}
		</Select>
	);

	const filter = inputType == "checkboxselect" ? checkboxFilter : selectFilter;

	return (
		<>
			<div className={`filter property-type-filter ${borderRadio&&'filter-with-border'}`}>
				{collapsable
					? <IC_Collapse label={country_code === 'CO' ? 'Tipo de Inmueble' : label} isActive={value.length >= 1} startOpen={value.length <= 0}>
						{filter}
					</IC_Collapse>
					: filter
				}
			</div>
			<style jsx global>{`
				.home-filters .property-type-filter .ant-select .ant-select-selector {
					text-overflow: ${value.length > 0 ? "ellipsis" : "unset"};
				}
			`}</style>
		</>
	);
};
