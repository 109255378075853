import "moment/locale/es";
import "./styles.less";

import { useContext } from "react";

import { DatePicker, Typography } from "antd";
import { DateRangeProps, useDateRange } from "./DateRange.hook";
import dayjs, { Dayjs } from "dayjs";

import { DateRangePickerMobile } from "./CustomCalendar/web";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useState } from "react";
import { ConfigStateContext } from "../../../../Contexts/Configurations/context";

const { RangePicker } = DatePicker;

moment.locale("es");

export const DateRange = ({ filterChanged }: DateRangeProps) => {
	const { country_code } = useContext(ConfigStateContext);
	const { dateFrom, dateTo } = useDateRange();
	dayjs.extend(customParseFormat);

	const screen = useBreakpoint();
	const [open, setOpen] = useState<boolean>(false);
	const vars: RangePickerDateProps<Dayjs> = {
		allowClear: true,
		format: "DD/MM/YYYY",
		defaultValue: [dayjs(new Date()), null],
		placeholder: ["Llegada", "Salida"],
		disabledDate: d => d.isBefore(dayjs(new Date()), "date"),
		value: [dateFrom ? dayjs(dateFrom) : null, dateTo ? dayjs(dateTo) : null],
		onChange: (date, dateString) => {
			filterChanged({
				dateFrom:
					date && date[0]
						? { value: date[0].format("YYYY/MM/DD"), text: dateString[0] }
						: null,
				dateTo:
					date && date[1]
						? { value: date[1].format("YYYY/MM/DD"), text: dateString[1] }
						: null,
				season: null,
			});
		},
		onOpenChange: e => setOpen(e),
		dropdownClassName: country_code
	};

	return (
		<>
			{/*@ts-ignore - because is an error from the lib, that we change to use dayjs instead of momentjs*/}
			<RangePicker
				locale={locale}
				className="secondary date-range"
				open={open && screen.lg}
				dropdownClassName="range-picker-custom secondary"
				{...vars}
			/>
			<DateRangePickerMobile
				open={open && !screen.lg}
				{...vars}
				clearIcon={
					<Typography.Text underline style={{ fontSize: "15px" }}>
						Borrar
					</Typography.Text>
				}
			/>
		</>
	);
};
