import { FragmentDefiner } from "../../../../GlobalHooks/useReadFragment";
import { useFilters } from "../../Filters.hook";

export const FRAGMENT_DATERANGE_OPTIONS = new FragmentDefiner(
	"Filter",
	`
      id
      name
`
);

export const FRAGMENT_DATERANGE = new FragmentDefiner(
	"Filters",
	`
      dateRange {
        ${FRAGMENT_DATERANGE_OPTIONS.query()}
      }
`
);

export interface DateRangeProps {
	filterChanged: ({}: any) => void;
}

const useDateRange = () => {
	const { filters } = useFilters();

	return {
		dateFrom: filters.dateFrom,
		dateTo: filters.dateTo,
	};
};

export { useDateRange };
