import { FragmentDefiner, useReadFragment } from "../../../../GlobalHooks/useReadFragment";

import { useFilters } from "../../Filters.hook";

const FRAGMENT_SEASONS_OPTIONS = new FragmentDefiner(
	"Filter",
	`
		id
		name
		options
`
);

export const FRAGMENT_SEASONS = new FragmentDefiner(
	"Filters",
	`
		seasons {
			${FRAGMENT_SEASONS_OPTIONS.query()}
		}
`
);

export interface SeasonFilterProps {
	filterChanged: ({  }: any) => void;
}

export const useSeasons = () => {
	const { loading, data, error } = useReadFragment(FRAGMENT_SEASONS_OPTIONS, "seasons");

	const { filters } = useFilters();

	return {
		initialValue: filters.season,
		options: { loading, options: data ? data.options : [] },
	};
};
