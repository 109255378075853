import "./styles.less";

import { SeasonFilterProps, useSeasons } from "./Seasons.hook";

import { Select } from "antd";

const { Option } = Select;

export const Seasons = ({ filterChanged }: SeasonFilterProps) => {
	const {
		initialValue,
		options: { loading, options },
	} = useSeasons();


	const seasonChange = (value, option) => {
		filterChanged({
			season: typeof option != "undefined" ? { text: option.children, value } : null,
			dateFrom: null,
			dateTo: null,
		});
	};

	return (
		<Select
			className="select-seasons secondary"
			allowClear
			disabled={loading}
			value={initialValue}
			onChange={seasonChange}
			placeholder="Temporada">
			{options?.map((opt, i) => {
				return (
					<Option key={`${opt.value}-${i}`} value={opt.value}>
						{opt.text}
					</Option>
				);
			})}
		</Select>
	);
};
