import { useEffect, useState } from "react"
import { DownOutlined } from "@ant-design/icons"

export const IC_Collapse = ({ startOpen = false, label = '', children, isActive = false, inHome=false }) => {
	const [open, setOpen] = useState(startOpen)
	
	useEffect(()=>{
		if(inHome){
			const handleClick = (e) => {
				if(e.target.closest('.ant-collapse-header') === null ){
					setOpen(false)
				}
			}
			document.addEventListener('click', handleClick)
			return () => document.removeEventListener('click', handleClick)
		}
	},[])

	useEffect(()=>{
		const handleClick = (e) => {
			if(e.target.closest('.ant-collapse-header') === null && !e.target.closest('.collapse-active')){
				setOpen(false)
			}
		}
		document.addEventListener('click', handleClick)
		return () => document.removeEventListener('click', handleClick)
	},[])

	return <>
		<div className="ant-collapse-header" style={{ display: "flex", alignItems: "center" }} onClick={() => { setOpen(!open) }}>
			{isActive &&
				<span className="active-dot"></span>
			}

			<span style={{ flex: "1 0 auto" }}>{label}</span>
			<DownOutlined rotate={open ? 180 : 0} style={{ marginRight: '3px' }} />

		</div>
		<div className={`ant-collapse-content ${open ? 'collapse-active' : 'collapse-inactive'}`} style={{ border: "none" }}>
			<div className="ant-collapse-content-box">
				{children}
			</div>
		</div>

		<style jsx>{`
			.ant-collapse-header{
				cursor: pointer;
			}
			.collapse-inactive{ 
				max-height: 0px;
				transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
			}
			.collapse-active{
				max-height: 100vh;
				transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
			}
		`}</style>
	</>
}