import "moment/locale/es";
import "./styles.less";

import { Button, Calendar, Col, Drawer, Row, Space, Typography } from "antd";
import {
	CloseCircleFilled,
	CloseOutlined,
	LeftOutlined,
	RightOutlined,
	SwapRightOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";

import { Dayjs } from "dayjs";
import { RangePickerDateProps } from "antd/lib/date-picker/generatePicker";
import locale from "antd/lib/date-picker/locale/es_ES";
import moment from "moment";

const { Text } = Typography;

moment.locale("es");

export const DateRangePickerMobile = ({
	open = false,
	value = [null, null],
	clearIcon = <CloseCircleFilled />,
	placeholder = ["From", "To"],
	format = "DD/MM/YYYY",
	onOpenChange = (open: boolean) => {},
	onChange = (dates: Array<Dayjs>, datesString: Array<string>) => {},
	allowClear = true,
	disabledDate = () => false,
}: RangePickerDateProps<Dayjs>) => {

	const [visible, setVisible] = useState(open);
	useEffect(() => setVisible(open), [open]);

	const [calendar, calendarChange] = useState();

	const [selected, setSelected] = useState<Array<Dayjs>>(value);
	useEffect(() => setSelected(value), [value]);

	const gatCellType = (d: Dayjs) => {
		let res: Array<CellType> = ["default"];
		if (d.isSame(selected[0], "date") || d.isSame(selected[1], "date")) res = ["selected"];
		else if (d.isAfter(selected[0], "date") && d.isBefore(selected[1], "date")) res = ["inner"];
		if (!d.isSame(calendar, "month")) res.push("otherMonth");
		if (disabledDate(d)) res.push("disabled");
		return res;
	};
	const onSelect = d => {
		const s = [...selected];
		if (!s[0] || (s[0] && s[1]) || d.isBefore(s[0], "date")) {
			s[0] = d;
			s[1] = null;
		} else s[1] = d;
		setSelected(s);
	};

	const onClose = () => {
		setVisible(false);
		onOpenChange(false);
	};

	const onClear = () => {
		setSelected([null, null]);
		onChange([null, null], ["", ""]);
	};

	const onSend = () => {
		onChange(
			[
				selected[0] == null ? null : selected[0].clone(),
				selected[1] == null ? null : selected[1].clone(),
			],
			[
				selected[0] == null
					? null
					: selected[0].format(Array.isArray(format) ? format[0] : format),
				selected[1] == null
					? null
					: selected[1].format(Array.isArray(format) ? format[1] : format),
			]
		);
		onClose();
	};

	return (
		<Drawer
			className="date-range-picker-mobile"
			onClose={onClose}
			visible={visible}
			closeIcon={null}
			placement={"bottom"}
			height={"auto"}
			style={{ maxHeight: "100%", overflow: "auto" }}
			title={
				<Row justify={"space-between"} align={"middle"}>
					<Col>
						<Button
							onClick={onClose}
							icon={<CloseOutlined />}
							type="text"
							shape={"circle"}
							size={"small"}
						/>
					</Col>
					{allowClear && (
						<Col>
							<div onClick={onClear}>{clearIcon}</div>
						</Col>
					)}
				</Row>
			}
			footer={
				<Row justify={"space-between"} align={"middle"} gutter={[0, 8]}>
					<Col span={11}>
						{selected[0] ? (
							<Space style={{ width: "100%" }} direction={"vertical"} size={0}>
								<Text type="secondary" style={{ fontSize: "15px" }}>
									{placeholder[0]}
								</Text>
								<Text>
									{selected[0].format(Array.isArray(format) ? format[0] : format)}
								</Text>
							</Space>
						) : (
							<Text disabled>{placeholder[0]}</Text>
						)}
					</Col>
					<Col>
						<Text disabled>
							<SwapRightOutlined />
						</Text>
					</Col>
					<Col span={11} style={{ textAlign: "right" }}>
						{selected[1] ? (
							<Space style={{ width: "100%" }} direction={"vertical"} size={0}>
								<Text type="secondary" style={{ fontSize: "15px" }}>
									{placeholder[1]}
								</Text>
								<Text>
									{selected[1].format(Array.isArray(format) ? format[1] : format)}
								</Text>
							</Space>
						) : (
							<Text disabled>{placeholder[1]}</Text>
						)}
					</Col>
					<Col span={24}>
						<Button
							disabled={!selected[0] && !selected[1]}
							onClick={onSend}
							children={"Continuar"}
							type={"primary"}
							style={{ width: "100%", borderRadius: "8px" }}
						/>
					</Col>
				</Row>
			}>
			<Calendar
				locale={locale}
				value={calendar}
				//@ts-ignore
				onPanelChange={v => calendarChange(v)}
				dateFullCellRender={d => (
					//@ts-ignore
					<CustomCalendarRenderCell date={d} onClick={onSelect} type={gatCellType(d)} />
				)}
				fullscreen={false}
				mode={"month"}
				headerRender={({ value: val, onChange }) => {
					const localeData = val.localeData();
					const current = val.clone();

					const changeMonth = (increase: boolean) => {
						let newValue;
						if (increase) newValue = val.clone().add(1, "month");
						else newValue = val.clone().subtract(1, "month");
						onChange(newValue);
					};

					return (
						<Row
							justify={"space-between"}
							align={"middle"}
							style={{ marginBottom: "12px" }}>
							<Col>
								<Button
									disabled={disabledDate(
										//@ts-ignore - because is lib error
										val.startOf("month").subtract(1, "day")
									)}
									shape="circle"
									icon={<LeftOutlined />}
									onClick={() => changeMonth(false)}
								/>
							</Col>
							<Col>
								<Typography.Text strong>
									{localeData.months(current) + " " + current.year()}
								</Typography.Text>
							</Col>
							<Col>
								<Button
									shape="circle"
									icon={<RightOutlined />}
									onClick={() => changeMonth(true)}
								/>
							</Col>
						</Row>
					);
				}}
			/>
		</Drawer>
	);
};

type CellType = "default" | "selected" | "inner" | "otherMonth" | "disabled";
export const CustomCalendarRenderCell = ({
	date,
	onClick,
	type = ["default"],
}: {
	date: Dayjs;
	onClick: (d: Dayjs) => void;
	type: Array<CellType>;
}) => {
	return (
		<div
			onClick={e => {
				e.stopPropagation();
				e.preventDefault();
				if (type.indexOf("disabled") < 0) onClick(date);
			}}
			className={"custom-cell-calendar " + type.join(" ")}>
			{date.date()}
		</div>
	);
};
