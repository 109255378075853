import { useState, useEffect } from "react";
import { useFilters } from "../Filters.hook";
import { showSeason, showDateRange } from "../../../Utils/Functions";

export const useTemporalFilter = () => {
	const { filters } = useFilters();
	const [showSeasonInput, setShowSeasonInput] = useState(filters.season ? true : false);
	const [isTemporal, setIsTemporal] = useState(false);

	useEffect(() => {
		setIsTemporal(
			showSeason(filters.operation_type_id, 1) &&
				showDateRange(filters.operation_type_id)
		); //@todo - add country id from hook, not hardcoded
	}, [filters]);

	return {
		filters,
		showSeasonInput: showSeasonInput,
		setShowSeasonInput: setShowSeasonInput,
		isTemporal: isTemporal,
	};
};
